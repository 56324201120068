<template>
  <Dialog
    :close-dialog="closeAdDialog"
    dialogOverlayClass="items-end md:items-center"
    dialog-class="flex w-full md:max-w-[790px] w-[97%] md:w-full  p-[24px_16px] md:p-[48px] flex-col items-center gap-[24px] md:gap-[40px] rounded-[16px_16px_0px_0px] md:rounded-[32px] bg-[var(--primary-settings-background)] shadow-[0px_-1px_1px_0px_rgba(226,23,120,0.35),0px_1px_2px_0px_rgba(226,23,120,0.35),0px_16px_24px_-8px_rgba(226,23,120,0.24)] backdrop-blur-[12px] relative"
  >
    <div
      @click="closeAdDialog"
      class="absolute right-[16px] top-[16px] flex p-[6px] cursor-pointer"
    >
      <cross-icon class="text-[--primary-invert-alfa-30]" />
    </div>
    <div class="flex flex-col gap-[16px] md:gap-[24px] self-stretch">
      <span class="text-[--primary-invert] text-[20px] md:text-[28px]  font-[700] leading-[120%] tracking-[0.4px]">
        {{ isPublished ? $t('common.ad') : $t('postAnnouncement.title') }}
      </span>
      <span class="text-[--primary-invert-alfa-60] text-[13px] md:text-[16px] font-[400] leading-[140%] md:tracking-[-0.25px]">
        {{ $t('postAnnouncement.description') }}
      </span>
    </div>

    <div class="flex flex-col items-start gap-[8px] md:gap-[16px] self-stretch">
      <div
        @click.stop="triggerFileInput"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover.prevent
        @drop="onDrop"
        class="flex p-[16px] md:p-[24px] box-border items-center gap-[16px] md:gap-[48px] self-stretch rounded-[16px] md:rounded-[32px] bg-[--primary-background-dark] cursor-pointer"
        :class="{ 'outline-dashed outline-2 outline-[--primary-pink]': isDragging }"
      >
        <div
          v-if="!imageUrl"
          class=" flex items-center justify-center bg-[--primary] rounded-[8px] md:rounded-[24px] w-[80px] h-[80px] md:w-[144px] md:h-[144px]"
        >
          <add-to-favorites-icon
            class="fill-[--primary-invert-alfa-40]"
            :class="{
              'add-to-favorites-icon-dragging': isDragging,
              'add-to-favorites-icon': !isDragging,
            }"
          />
        </div>
        <img
          v-else
          :src="imageUrl"
          alt="Загруженное изображение"
          class="w-[80px] h-[80px] md:w-[144px] md:h-[144px] rounded-[8px] md:rounded-[24px] object-cover bg-cover"
        />
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          accept="image/*"
          @change="handleFileChange"
        />

        <div class="flex flex-col justify-center items-start gap-[24px] flex-1">
          <span
            class="text-[--primary-invert-alfa-40] text-[13px] md:text-[16px] font-[400] leading-[140%] md:leading-[150%] md:tracking-[-0.25px]"
            :class="{'hidden md:flex': imageUrl}"
          >
            {{ message }}
          </span>
          <div
            v-if="imageUrl && !isPublished"
            @click.stop="removeImage"
            class="flex justify-center items-center gap-[6px] cursor-pointer"
          >
            <span class="p-[4px] flex items-center">
              <cross-icon class="text-[--primary-invert-alfa-30] w-[8px] h-[8px]"/>
            </span>
            <span class="text-[--primary-invert-alfa-40] text-[16px] md:text-[16px] font-[400] tracking-[0.2px]">
              {{ $t('postAnnouncement.photo.title') }}
            </span>
          </div>
        </div>
      </div>
      <div class=" w-full">
        <Textarea
          :maxLength="360"
          :value="offerText"
          :handle-textarea-change="(value) => handleTextChange(value)"
          :placeholder="$t('postAnnouncement.inputPlaceholder')"
          :is-disabled="isPublished"
        />
      </div>
    </div>
    <div
      v-if="isPublished"
      class="flex items-center gap-[24px]"
    >
      <div
        class="flex p-[8px_10px] justify-center align-center gap-[8px] rounded-[16px] bg-[--primary-pink]"
      >
        <span class="text-center text-[16px] font-bold leading-[120%] tracking-[0.8px] uppercase text[--primary-invert-alfa-60]">
        {{ $t('common.published') }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="flex items-center gap-[24px] md:self-stretch w-inherit"
    >
      <div
        class="flex items-center gap-[8px] md:gap-[12px] flex-1 w-inherit overflow-hidden"
        :class="{
          'justify-center' : !countLimit
        }"
      >
        <div
          class="flex p-[6px_8px] md:p-[8px_10px] justify-center items-center gap-[8px] rounded-[16px] "
          :class="{'bg-[--primary-pink]': countLimit,'bg-[--primary-invert-alfa-30]': !countLimit }"
        >
         <span class="text-[--primary-invert-alfa-60] text-[12px] md:text-[16px] font-[700] leading-[90%] tracking-[0.4px] md:tracking-[0.8px] uppercase flex-1">
           {{ countLimit }}
         </span>
        </div>
        <span class="text-[--primary-invert-alfa-60] text-[11px] md:text-[12px] font-normal leading-[150%] tracking-[0.6px] truncate ">
          {{ limitMessage  }}
        </span>
      </div>
      <Button
        v-if="countLimit"
        size="none"
        custom-class="p-[14px_20px] md:p-[16px_18px]"
        :text="$t('button.publish')"
        @handle-click="addAds"
      />
    </div>
  </Dialog>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { i18n } from '@/i18n';
import Button from '@/common/Button.vue';
import Dialog from '@/common/Dialog.vue';
import Textarea from '@/common/Textarea.vue';
import { handleAddAds, handleGetUserActiveAds } from '@/api/Profile';
import CrossIcon from '@/assets/icons/cross.vue';
import AddToFavoritesIcon from '@/assets/icons/addToFavoritesIcon.vue';

const store = useStore();

const countLimit = ref(0);
const offerText = ref('');
const fileInput = ref(null);
const image = ref(null);
const imageUrl = ref('');
const isDragging = ref(false);
const isPublished = ref(false);

const limitMessage  = computed(() => {
  return countLimit.value ? i18n.global.t('postAnnouncement.freeAds'): i18n.global.t('postAnnouncement.notFreeAds');
});

const message = computed(()=> {
  if(isPublished.value) return i18n.global.t('postAnnouncement.coverYourAd');

  return i18n.global.t('postAnnouncement.photo.description');
});

onMounted(() => {
  getUserAds();
});

function closeAdDialog() {
  store.dispatch('main/closeDialog', 'adPlace');
}

async function getUserAds() {
  const response = await handleGetUserActiveAds();

  if (response && response.status === 200) {
    const { content, photo_url } = response?.data?.ad || {};

    countLimit.value = response?.data?.remaining_ads_count || 0;
    offerText.value = content || '';
    imageUrl.value = photo_url || '';

    if (content) {
      isPublished.value = true;
    }
  }
}

async function addAds () {
  if(!offerText.value) return;

  const data = {
    content: offerText.value,
    photo: image.value?.file || null
  };

  const response = await handleAddAds(data);

  if (response && response.status === 200 || response.status === 201) {
    closeAdDialog();
  }
}

function triggerFileInput() {
  if (isPublished.value || !countLimit.value) return;

  if (!image.value) {
    fileInput.value.click();
  }
}

function handleFileChange(event) {
  const file = event.target.files[0];
  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];

  if (validImageTypes.includes(file?.type)) {
    processFiles(file);
  }

  event.target.value = '';
}

function onDragEnter() {
  if (isPublished.value || !countLimit.value) return;

  isDragging.value = true;
}

function onDragLeave() {
  isDragging.value = false;
}

function onDrop(event) {
  if (isPublished.value || !countLimit.value) return;

  event.preventDefault();
  isDragging.value = false;

  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
  const file = event.dataTransfer.files[0];

  if (validImageTypes.includes(file?.type)) {
    processFiles(file);
  }
}
function removeImage(){
  image.value = null;
  imageUrl.value = '';
}
function handleTextChange(value){
  offerText.value = value;
}

function processFiles(file) {
  const selectedImage = [];
  const reader = new FileReader();

  reader.onload = (e) => {
    const url = e.target.result;

    selectedImage.push({ url, file });

    image.value = { url, file };
    imageUrl.value = URL.createObjectURL(image.value.file);
  };

  reader.readAsDataURL(file);
}
</script>
<style>
.add-to-favorites-icon-dragging path {
  fill: var(--primary-pink);
}

.add-to-favorites-icon path {
  fill: var(--primary-invert-alfa-40);
}
</style>
