<template>
  <button
      :class="[
      'button-wrapper flex',
      `button-wrapper--${size}`,
       customClass,
       {
         'border-[--primary-invert-alfa-30] opacity-20 cursor-auto pointer-events-none': disabled
       }
    ]"
    @click="handleButtonClick"
  >
    <span
      class="button-wrapper-text"
      :class="[
        buttonTextClass,
        { 'text-[16px] font-[700] tracking-[0.8px]': !buttonTextClass }
      ]"
      v-if="text"
    >
      {{ text }}
    </span>
    <slot name="icon" />
  </button>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'lg',
  },
  customClass: {
    type: String,
    default: '',
  },
  buttonTextClass: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['handleClick']);

function handleButtonClick() {
  if (props.disabled) return;

  emit('handleClick');
}
</script>

<style lang="scss">
.button-wrapper {
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: transparent;
  border: 2px solid var(--primary-invert-alfa-30);
  cursor: pointer;
  transition: all 0.3s ease;

  &--lg {
    padding: 24px;
  }

  &--md {
    padding: 16px 18px;
  }

  &-text {
    color: var(--primary-invert);
    font-style: normal;
    line-height: 120%;
    text-transform: uppercase;
  }

  &:hover {
    background: var(--primary-pink);
    box-shadow: 0px -1px 1px 0px var(--auth-register-login-button-shadow-alfa-35), 0px 1px 2px 0px var(--auth-register-login-button-shadow-alfa-35), 0px 16px 24px -8px var(--auth-register-login-button-shadow-alfa-24);
    backdrop-filter: blur(12px);
  }
}
</style>