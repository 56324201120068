import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const echo = new Echo({
  broadcaster: 'reverb',
  key: process.env.VUE_APP_REVERB_KEY,
  wsHost: process.env.VUE_APP_REVERB_HOST || '127.0.0.1',
  wsPort: process.env.VUE_APP_REVERB_PORT || 8081,
  wssPort: process.env.VUE_APP_REVERB_PORT || 8081,
  scheme: process.env.VUE_APP_REVERB_SCHEME || 'http',
  forceTLS: process.env.VUE_APP_REVERB_SCHEME === 'https',
  enabledTransports: ['ws', 'wss'],
  withCredentials: true,
  authEndpoint: `${process.env.VUE_APP_BASE_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  },
});

export default echo;
