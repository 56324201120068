<template>
  <div
    :class="[
      'dialog-wrapper-overlay',
      dialogOverlayClass
    ]"
    @click="closeDialog"
  >
    <div
      :class="[
        dialogClass,
        'dialog-wrapper-content',
        isMobile ? 'max-h-[100vh] md:max-h-[90vh]': 'max-h-[90vh]'
      ]"
      @click.stop
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';

defineProps({
  dialogClass: {
    type: String,
    default: '',
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
  dialogOverlayClass: {
    type: String,
    default: 'items-center',
  },
  closeDialog: {
    type: Function,
    default: () => {
    },
  },
});

const preventScroll = () => {
  document.body.style.overflow = 'hidden';
};

const enableScroll = () => {
  document.body.style.overflow = '';
};

onMounted(() => {
  preventScroll();
});

onUnmounted(() => {
  enableScroll();
});
</script>

<style lang="scss">
.dialog-wrapper-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary-alfa-50);
  display: flex;
  justify-content: center;
  z-index: 1000;
}
</style>
